.Toastify__toast--success {
  background: rgb(237, 247, 237);
}
.Toastify__toast--info {
  background: rgb(232, 244, 253);
}
.Toastify__toast--warning {
  background: rgb(255, 244, 229);
}
.Toastify__toast--error {
  background: rgb(253, 236, 234);
}
.Toastify__close-button > svg {
  color: black;
}
.Toastify__toast-container {
  width: 50%;
}